import React, {useState} from 'react';
import { getOrientation } from 'get-orientation/browser';
import { getRotatedImage } from './../utils/rotateImage';
import { useHistory } from 'react-router-dom';
import loadMime from '../utils/loadMime';
import PropTypes from 'prop-types';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

Choose.propTypes = {
  setFileName: PropTypes.func,
  setImageExt: PropTypes.func,
  setImageLink: PropTypes.func,
  setImageSizes: PropTypes.func,
  setStage: PropTypes.func
}

function checkextension (fileName) {
  return /\.(jpe?g)|(png)|(bmp)|(gif)$/i.test(fileName);
}

function readFile (file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

function Choose ({setImageExt, setFileName, setImageSizes, setImageLink, setStage}) {
  const [over, setOver] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const onFileChange = async e => {
    setOver(false);

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setError(null);
      if (file.size > 5000000) {
        setError('The photo size must not be greater than 5 Mb.');
        return;
      }
      if (!checkextension(file.name)) {
        setError('File format must be jpg png jpeg');
        return;
      }
      loadMime(file, (mime) => {
        setImageExt(mime);
      })
      setFileName(file.name);
      let imageDataUrl = await readFile(file);
      let image = '';
      image = await new Promise((res, rej) => {
        const i = new Image();
        i.src = imageDataUrl;
        i.onerror = () => {
          rej('Corrupted image');
          setError('Corrupted image');
        };
        i.onload = () => {
          if (i.width < 500 || i.height < 500) {
            setError('Minimum width and height is 500 px');
            return;
          }
          res('success');
          setImageSizes({ height: i.height, width: i.width, scaleX: i.naturalWidth / i.width, scaleY: i.naturalHeight / i.height });
        };
      });
      if (image === 'Corrupted image') {
        return;
      }
      if (image === 'Minimum width and height is 300 px') {
        return;
      }
      if ((imageDataUrl[imageDataUrl.length - 1] === 217) && (imageDataUrl[imageDataUrl.length - 2] === 255)) {
        setError('Image is corrupted');
        return;
      }
      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
      setImageLink(imageDataUrl);
      history.push('/crop');
      setStage(1);
    }
  };

  return (
    <div className="choose">
      <p>
        <span style={over ? { border: '2px dashed black' } : {}}>{''}</span>
        <span>Drag your photo here</span>
      </p>
      <input onChange={onFileChange}
             onClick={(e) => e.preventDefault()}
             onDragEnter={() => {setOver(true);}}
             onDragLeave={() => setOver(false)} type="file" id="file"/>
      <span style={{ fontSize: 36 }}>or</span>
      <input type="file" onChange={onFileChange} style={{display: 'none'}} id="no-drag-file" />
      <label htmlFor="no-drag-file">
        <span>Select a photo from your computer</span>
      </label>
      <span>Minimum size of 500x500 jpeg jpg png 5 MB</span>
      <p className="choose__error">{error}</p>
    </div>
  );
}

export default Choose;
