import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Slider} from '@material-ui/core';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../utils/cropImage'
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';

Crop.propTypes = {
  imageLink: PropTypes.string,
  imageSizes: PropTypes.object.isRequired,
  setCroppedImage: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired
}

function Crop({imageSizes, imageLink, imageExt, setCroppedImage, setStage, setCoordinates}) {
    const minZoom = Math.max(imageSizes.width, imageSizes.height) / Math.min(imageSizes.width, imageSizes.height);
    const maxZoomCoef = Math.min(2, Math.min(imageSizes.width, imageSizes.height) / 500);
    const maxZoom = minZoom * maxZoomCoef;
    const zoomStep = (maxZoom - minZoom) / 10;

    const [crop, setCrop] = useState({x: 0, y: 0, width: 500, height: 500});
    const [zoom, setZoom] = useState(minZoom);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const history = useHistory();

    const onCropChange = crop => {
        setCrop({x: crop.x, y: crop.y, width: 500, height: 500});
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
      setCoordinates({
        x1: croppedAreaPixels.x * imageSizes.scaleX,
        x2: croppedAreaPixels.x * imageSizes.scaleX + croppedAreaPixels.width * imageSizes.scaleX,
        y1: croppedAreaPixels.y * imageSizes.scaleY,
        y2: croppedAreaPixels.y * imageSizes.scaleY + croppedAreaPixels.height * imageSizes.scaleY,
      })

        setCroppedAreaPixels(croppedAreaPixels);
    }

    const onZoomChange = zoom => {
        setZoom(zoom);
    }
    return (
        <div className="crop">
            <div className="crop-container">
                <Cropper
                    image={imageLink}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    cropSize={{width: 500, height: 500}}
                    showGrid={false}
                    minZoom={minZoom}
                    maxZoom={maxZoom}
                />
            </div>
            <div className="controls">
                <Slider
                    value={zoom}
                    min={minZoom}
                    max={maxZoom}
                    step={zoomStep}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => onZoomChange(zoom)}
                />
            </div>
            <div className="button">
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  history.push('/');
                  setStage(0);
                }}
              >
                Change
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isCropping}
                onClick={async () => {
                  try {
                    setIsCropping(true);
                    const croppedImage = await getCroppedImg(
                      imageLink,
                      croppedAreaPixels,
                      imageExt
                    )
                    console.log('done', {croppedImage})
                    setCroppedImage({croppedImage, crop});
                    setIsCropping(false);
                    history.push('/result');
                    setStage(2);
                  } catch (e) {
                    console.error(e)
                    setIsCropping(false);
                    history.push('/');
                    setStage(0);
                  }
                }}
              >
                Apply
              </Button>
            </div>
        </div>
    );
}

export default Crop;
