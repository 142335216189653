import React from 'react';
import {Container} from '@material-ui/core';

function Footer () {
  return (
    <div className="footer">
      <Container>
        Abz.agency
      </Container>
    </div>
  );
}

export default Footer;
