import React from 'react';
import PropTypes from 'prop-types';
import Steps from '../components/Steps';
import {Container} from '@material-ui/core';

Header.propTypes = {
  stage: PropTypes.number.isRequired
};

function Header ({stage}) {
  return (
    <div className="header">
      <Container>
        <div className="header__wrapper">
          <h1>Upload your photo</h1>
          <Steps stage={stage} />
        </div>
      </Container>
    </div>
  );
}

export default Header;
