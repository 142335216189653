import React, {useState} from 'react';
import { Route, Switch } from 'react-router-dom';
import Choose from './pages/Choose';
import Crop from './pages/Crop';
import Result from './pages/Result';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Container } from '@material-ui/core';
import Resized from './pages/Resized';
console.log(process.env)
require('blueimp-canvas-to-blob');

function App () {
  const [imageLink, setImageLink] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [stage, setStage] = useState(0);
  const [imageExt, setImageExt] = useState(null);
  const [imageSizes, setImageSizes] = useState({ width: 0, height: 0 });
  const [coordinates, setCoordinates] = useState({ x1: 0, x2: 0, y1: 0, y2: 0 });
  const [fileName, setFileName] = useState(null);
  const [images, setImages] = useState({});
  return (
    <div className="App">
      <Header stage={stage}/>
      <main>
        <Container>
          <Switch>
            <Route exact path="/">
              <Choose setImageLink={setImageLink} setStage={setStage} setImageExt={setImageExt}
                      setImageSizes={setImageSizes} setFileName={setFileName}/>
            </Route>
            <Route path="/crop">
              <Crop imageLink={imageLink} setCroppedImage={setCroppedImage} setStage={setStage}
                    imageExt={imageExt} imageSizes={imageSizes} setCoordinates={setCoordinates}/>
            </Route>
            <Route path="/result">
              <Result imageLink={imageLink} croppedImage={croppedImage} setStage={setStage}
                      coordinates={coordinates} imageExt={imageExt} fileName={fileName}
                      setImages={setImages}/>
            </Route>
            <Route path="/resized">
              <Resized images={images}/>
            </Route>
            <Route>
              <Choose/>
            </Route>
          </Switch>
        </Container>
      </main>
      <Footer/>
    </div>
  );
}

export default App;
