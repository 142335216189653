import React from 'react';
import PropTypes from 'prop-types';

Resized.propTypes = {
  images: PropTypes.object.isRequired
}

function generateInfo(obj, format) {
  console.log(obj);
  return `
  ( file size: ${obj.size.toFixed(4)} MB, image size: ${obj.dimensions.width} x ${obj.dimensions.height}, format: ${format || obj.dimensions.type} )
  `
}

function Resized ({images}) {
  const {jpeg, png, gif, bmp} = images;
  return (
    <div className="resized">
      <div className="resized__row">
        <p>Resized to 500x500</p>
        <div className="resized__item">
          <img src={jpeg['500x500'].url} alt=""/>
          <p>{generateInfo(jpeg['500x500'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={png['500x500'].url} alt=""/>
          <p>{generateInfo(png['500x500'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={gif['500x500'].url} alt=""/>
          <p>{generateInfo(png['500x500'].fileInfo, 'gif')}</p>
        </div>
        <div className="resized__item">
          <img src={bmp['500x500'].url} alt=""/>
          <p>{generateInfo(bmp['500x500'].fileInfo)}</p>
        </div>
      </div>
      <div className="resized__row">
        <p>JPEG</p>
        <div className="resized__item">
          <img src={jpeg['50x50'].url} alt=""/>
          <p>{generateInfo(jpeg['50x50'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['100x100'].url} alt=""/>
          <p>{generateInfo(jpeg['100x100'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['150x150'].url} alt=""/>
          <p>{generateInfo(jpeg['150x150'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['200x200'].url} alt=""/>
          <p>{generateInfo(jpeg['200x200'].fileInfo)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['350x350'].url} alt=""/>
          <p>{generateInfo(jpeg['350x350'].fileInfo)}</p>
        </div>
      </div>
      <div className="resized__row">
        <p>Optimized</p>
        <div className="resized__item">
          <img src={jpeg['500x500'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['500x500'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={png['500x500'].optimized.url} alt=""/>
          <p>{generateInfo(png['500x500'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={gif['500x500'].optimized.url} alt=""/>
          <p>{generateInfo(gif['500x500'].optimized, 'gif')}</p>
        </div>
        <div className="resized__item">
          <img src={bmp['500x500'].optimized.url} alt=""/>
          <p>{generateInfo(bmp['500x500'].optimized)}</p>
        </div>
      </div>
      <div className="resized__row">
        <p>optimized JPEG</p>
        <div className="resized__item">
          <img src={jpeg['50x50'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['50x50'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['100x100'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['100x100'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['150x150'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['150x150'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['200x200'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['200x200'].optimized)}</p>
        </div>
        <div className="resized__item">
          <img src={jpeg['350x350'].optimized.url} alt=""/>
          <p>{generateInfo(jpeg['350x350'].optimized)}</p>
        </div>
      </div>
      <div className="resized__row resized__comp">
        <p>
          <span></span>
          <span>Original</span>
          <span>Compressed</span>
        </p>
        {
          Object.keys(images).map((item, i) => {
            return Object.keys(images[item]).map((size, index) => {
              return (
                <p key={i + index}>
                  <span>{item} {size}</span>
                  <span>{images[item][size].fileInfo.size.toFixed(6)} Mb</span>
                  <span>{images[item][size].optimized.size.toFixed(6)} Mb</span>
                </p>
              )
            })
          })
        }
      </div>
    </div>
  );
}

export default Resized;
