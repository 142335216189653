import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

Result.propTypes = {};

function urltoFile(url, filename, mimeType){
  return (fetch(url)
    .then(function(res){return res.arrayBuffer();})
    .then(function(buf){return new File([buf], filename,{type:mimeType});})
  );
}

function Result ({imageLink, fileName, imageExt, croppedImage, coordinates, setStage, setImages}) {
  const history = useHistory();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      <img style={{ width: 500 }} src={croppedImage.croppedImage} alt=""/>
      <p style={{
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
        alignSelf: 'flex-start',
        fontSize: 20,
        margin: '20px 0',
        width: '100%',
        textAlign: 'center',
      }}>{croppedImage.croppedImage}</p>
      <p>x1: {Math.round(coordinates.x1)} y1: {Math.round(coordinates.y1)}</p>
      <p>x2: {Math.round(coordinates.x2)} y2: {Math.round(coordinates.y2)}</p>
      <Button
        style={{ marginTop: 20 }}
        color="primary"
        variant="contained" onClick={() => {
        history.push('/');
        setStage(0);
      }}>Home</Button>
    </div>
  );
}

export default Result;
