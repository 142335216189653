import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

ReactDOM.render(<ThemeProvider theme={theme}>
  <CssBaseline />
  <BrowserRouter><App /></BrowserRouter>
</ThemeProvider>, document.getElementById('root'));

serviceWorker.unregister();
